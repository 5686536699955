import React from 'react';
import PropTypes from 'prop-types';
import { Container, NavDropdown, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'; //faBell
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import 'assets/scss/section/_header.scss';
import profile_image_icon from 'assets/images/profile.svg';
import { logoutSuccess, loggedUser, setNotificationCount } from 'store';
import { useNotificationCount } from 'hooks';
import { LogoUrlFront } from 'common';
import { LOGIN, EDIT_PROFILE, NOTIFICATIONS } from 'config';

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationCount = useSelector((state) => state.notifications.notificationCount); // Access notificationCount from Redux store
  // Hook call to fetch notification count
  useNotificationCount((res) => {
    if (res.data) {
      const newNotificationCount = res.data.user_notification_count;
      dispatch(setNotificationCount(newNotificationCount));
    }
  });
  const handleNotificationClick = () => {
    navigate(NOTIFICATIONS); // Replace with the path you want to navigate to
  };

  const handleEditProfile = () => {
    navigate(EDIT_PROFILE);
  };

  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate(LOGIN);
  };

  const checkLoggedInUser = useSelector(loggedUser);

  const UserMenu = (
    <svg width="9" height="21" viewBox="0 0 9 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="4.44691" cy="4.49619" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="10.4886" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="16.4809" rx="1.44691" ry="1.49619" fill="#9592A6" />
    </svg>
  );

  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo">
            <LogoUrlFront />
          </Navbar.Brand>

          <div className="header-setting-menu">
            <div className="dashboard-toggle-btn">
              <FontAwesomeIcon icon={faBars} onClick={props.toggleClass} />
            </div>
            <div
              className="notification-wrapper"
              onClick={handleNotificationClick}
              style={{ cursor: 'pointer' }}>
              <span className={`notification-counter ${!notificationCount ? 'd-none' : ''}`}>
                {notificationCount ? (notificationCount > 10 ? '+9' : notificationCount) : null}
              </span>
              {/* <FontAwesomeIcon icon={faBell} className="me-2" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-bell"
                viewBox="0 0 16 16">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
              </svg>
            </div>

            <div className="profile-img">
              <img
                src={
                  checkLoggedInUser.user.profile_image
                    ? checkLoggedInUser.user.profile_image
                    : profile_image_icon
                }
                width="40px"
                height="40px"
                alt="profile_img"
              />
            </div>
            <div className="desktop-login-name">
              <span className="admin-name-dynamic">
                {checkLoggedInUser.user.first_name + ' ' + checkLoggedInUser.user.last_name}
              </span>
            </div>
            <NavDropdown title={UserMenu} className="me-md-2">
              <div className="mobile-login-name">
                <span>
                  {checkLoggedInUser.user.first_name + ' ' + checkLoggedInUser.user.last_name}
                </span>
                <NavDropdown.Divider className="separator" />
              </div>
              <NavDropdown.Item className="setting-items" onClick={handleEditProfile}>
                {props.t('page.header_edit_profile')}
              </NavDropdown.Item>
              <NavDropdown.Item className="setting-items" onClick={handleLogout}>
                {props.t('page.header_logout')}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  toggleClass: PropTypes.any.isRequired,
  t: PropTypes.func,
};

export { Header };
